import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import PlayerLobby from "./PlayerLobby";
import PlayerSetup from "./PlayerSetup";
import gameSteps from "../../constants/gameSteps";
import PlayerStart from "./PlayerStart";
import Logo from "../../components/Logo";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#282c34",
    minHeight: "94vh",
    display: "flex",
    fontSize: "calc(10px + 2vmin)",
    flexDirection: "column",
    flex: "1",
  },
  content: {
    flex: "2 1",
    //marginTop: "-3em",
  },
}));

const PlayerLobbyScreen = ({ step, joinRoom, playerReady, startGame }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box flex={"1.3 1"} />
      <Logo />
      <div className={classes.content}>
        {step === gameSteps.JOINING_ROOM && <PlayerLobby joinRoom={joinRoom} />}
        {step === gameSteps.PLAYER_SETUP && (
          <PlayerSetup playerReady={playerReady} />
        )}
        {step === gameSteps.WAITING_START && (
          <PlayerStart startGame={startGame} />
        )}
      </div>
    </div>
  );
};

export default PlayerLobbyScreen;
