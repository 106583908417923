import LogRocket from "logrocket";
import uuid from "uuid";
const uid = uuid.v4();

let initialized = false;
const init = () => {
  console.log("initializing logging");

  LogRocket.init("iyahub/playbox");
  LogRocket.identify(uid);
  initialized = true;
};

export const identify = props => {
  if (!initialized) {
    return;
  }
  LogRocket.identify(uid, props);
};

export default { uid, identify, init };
