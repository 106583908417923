import React from "react";

const BoxIcon = ({
  style = {},
  fill = "#000",
  width = "100%",
  className = "",
  viewBox = "0 0 512 512",
}) => (
  <svg
    width={width}
    style={style}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M459.5,134.468,259.5,19a7,7,0,0,0-7,0L52.5,134.468A7,7,0,0,0,49,140.53V371.47a7,7,0,0,0,3.5,6.062L252.5,493a7.005,7.005,0,0,0,7,0l200-115.471a7,7,0,0,0,3.5-6.062V140.53A7,7,0,0,0,459.5,134.468ZM256,33.143l84.024,48.511L154.038,189.033,70.014,140.522Zm-7,441.673L63,367.429V152.638L249,260.026ZM256,247.9l-87.961-50.785L354.025,89.737l87.961,50.785ZM449,367.429,263,474.816V260.026L449,152.638Z"
    />
  </svg>
);

export default BoxIcon;
