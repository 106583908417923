const getRoomState = state => {
  return state.room;
};

const getRoomNumber = state => {
  return state.room.roomNumber;
};

const getParticipants = state => {
  return state.room.participants.map(p => {
    return {
      id: p,
      name:
        state.room.players[p] !== undefined ? state.room.players[p].name : null,
    };
  });
};

const getMyId = state => {
  return state.room.me;
};

const getPlayer = (state, playerId) => {
  return state.room.players.hasOwnProperty(playerId)
    ? state.room.players[playerId]
    : null;
};

export default {
  getRoomState,
  getRoomNumber,
  getParticipants,
  getMyId,
  getPlayer,
};
