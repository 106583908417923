import { roomTypes, roomOperations, roomSelectors } from "../modules/room";
import { coreTypes, coreOperations, coreSelectors } from "../modules/core";
import { gameTypes, gameOperations, gameSelectors } from "../modules/game";
import socketEvents from "./socketEvents";
import * as io from "socket.io-client";

const onConnect = mwApi => () => {
  mwApi.dispatch(coreOperations.initConnectionSuccess());
};
const onError = mwApi => error => {
  mwApi.dispatch(coreOperations.initConnectionError(error));
};
const onPlayerJoined = mwApi => payload => {
  console.log("player joined");
  mwApi.dispatch(roomOperations.playerJoined(payload));
};
const onPlayerReady = mwApi => payload => {
  console.log("player ready");
  mwApi.dispatch(roomOperations.playerIsReady(payload));
};
const onPlayerDisconnected = mwApi => payload => {
  console.log("player disconnected");
  mwApi.dispatch(roomOperations.playerDisconnected(payload));

  const state = mwApi.getState();
  const isHost = coreSelectors.isHost(state);
  if (!isHost) {
    return;
  }

  const { id } = payload;
  const player = roomSelectors.getPlayer(state, id);
  if (player !== null) {
    mwApi.dispatch(
      coreOperations.enqueueSnackbar({
        message: `${player.name} disconnected`,
      }),
    );
  }
};
const onBeginGame = mwApi => payload => {
  console.log("beginning game");
  console.log(payload);
  mwApi.dispatch(coreOperations.beginGame(payload));
};
const onInitializeClient = mwApi => payload => {
  console.log("initializing client");
  console.log(payload);
  mwApi.dispatch(gameOperations.setGameState(payload));
};
const onSetTurn = mwApi => payload => {
  console.log("setting Turn");
  console.log(payload);
  mwApi.dispatch(gameOperations.setTurn(payload));
};
const onClueSubmitted = mwApi => payload => {
  console.log("clue submited");
  console.log(payload);
  mwApi.dispatch(gameOperations.setClues(payload));
};
const onGuessSubmitted = mwApi => payload => {
  console.log("guess submited");
  console.log(payload);
  mwApi.dispatch(gameOperations.setGuess(payload));
};

const onPlayerActive = mwApi => payload => {
  console.log("setting client as active");
  console.log(payload);
  mwApi.dispatch(gameOperations.beginGame(payload));
};
const onPlayerOther = mwApi => payload => {
  console.log("setting client as other player");
  console.log(payload);
  mwApi.dispatch(coreOperations.beginGame(payload));
};
const onReconnect = mwApi => () => {
  const state = mwApi.getState();
  const room = roomSelectors.getRoomState(state);
  socket.emit("reconnected", room, onPlayerReconnect(mwApi));
};
const onPlayerReconnect = mwApi => payload => {
  mwApi.dispatch(roomOperations.playerReconnected(payload));

  const state = mwApi.getState();
  const isHost = coreSelectors.isHost(state);
  if (!isHost) {
    return;
  }
  const { newId } = payload;
  const player = roomSelectors.getPlayer(state, newId);

  if (player !== null) {
    mwApi.dispatch(
      coreOperations.enqueueSnackbar({
        message: `${player.name} reconnected`,
      }),
    );
  }
};
const onSyncRequest = mwApi => playerId => {
  console.log("syncing rquest");
  const state = mwApi.getState();
  const gameState = gameSelectors.getGameState(state);
  const payload = { playerId, gameState };
  socket.emit("syncState", payload);
};
const onSyncClient = mwApi => payload => {
  console.log(payload);
  mwApi.dispatch(gameOperations.syncClient(payload));
};

let socket;
const socketMiddleware = mwApi => next => action => {
  if (action.type === coreTypes.INIT_CONNECTION) {
    if (socket === undefined) {
      console.log("connecting to server");
      socket = io(process.env.REACT_APP_SERVER_URI);

      //Handlers
      socket.on(socketEvents.CONNECT, onConnect(mwApi));
      socket.on(socketEvents.ERROR, onError(mwApi));
      socket.on(socketEvents.RECONNECT, onReconnect(mwApi));
      socket.on(socketEvents.PLAYER_JOINED, onPlayerJoined(mwApi));
      socket.on(socketEvents.PLAYER_READY, onPlayerReady(mwApi));
      socket.on(socketEvents.PLAYER_DISCONNECTED, onPlayerDisconnected(mwApi));
      socket.on(socketEvents.PLAYER_RECONNECTED, onPlayerReconnect(mwApi));

      socket.on(socketEvents.BEGIN_GAME, onBeginGame(mwApi));
      socket.on(socketEvents.INITIALIZE_CLIENT, onInitializeClient(mwApi));

      socket.on(socketEvents.SET_TURN, onSetTurn(mwApi));
      socket.on(socketEvents.CLUE_SUBMITTED, onClueSubmitted(mwApi));
      socket.on(socketEvents.GUESS_SUBMITTED, onGuessSubmitted(mwApi));

      socket.on(socketEvents.SET_PLAYER_ACTIVE, onPlayerActive(mwApi));
      socket.on(socketEvents.SET_PLAYER_OTHER, onPlayerOther(mwApi));

      socket.on(socketEvents.SYNC_REQUEST, onSyncRequest(mwApi));
      socket.on(socketEvents.SYNC_CLIENT, onSyncClient(mwApi));
    }
  }

  if (action.type === coreTypes.PLAYER_READY) {
    socket.emit(socketEvents.PLAYER_READY, action.playerSettings, response => {
      console.log(response);
      mwApi.dispatch(coreOperations.playerReadySuccess(response));
    });
  }

  if (action.type === roomTypes.CREATE_ROOM) {
    socket.emit(socketEvents.CREATE_ROOM, response => {
      console.log(response);
      mwApi.dispatch(roomOperations.createRoomSuccess(response));
    });
  }

  if (action.type === roomTypes.JOIN_ROOM) {
    socket.emit("joinRoom", action.roomNumber, response => {
      console.log(response);
      const { status } = response;
      if (status === "NO_ROOM") {
        mwApi.dispatch(roomOperations.joinRoomError(response));
      } else {
        mwApi.dispatch(roomOperations.joinRoomSuccess(response));
      }
    });
  }

  if (action.type === coreTypes.START_GAME) {
    socket.emit("startGame", response => {
      console.log(response);
      const { status } = response;
      if (status === "STARTING") {
        mwApi.dispatch(coreOperations.startGameSuccess(response));
      } else {
        mwApi.dispatch(coreOperations.startGameError(response));
      }
    });
  }

  if (action.type === gameTypes.INITIALIZE_CLIENTS) {
    // const word = gameSelectors.getWord(state);
    // const activePlayerId = gameSelectors.getActivePlayerId(state);
    // const players = roomSelectors
    //   .getParticipants(state)
    //   .filter(p => p !== activePlayerId);

    // const payload = {
    //   activePlayerId,
    //   players,
    //   word,
    // };

    socket.emit("initializeClients", action.gameState, response => {
      console.log(response);
      const { status } = response;
      if (status === "INITIALIZED") {
        mwApi.dispatch(gameOperations.initializeClientsSuccess(response));
      } else {
        mwApi.dispatch(gameOperations.initializeClientsError(response));
      }
    });
  }

  if (action.type === gameTypes.SEND_PLAYER_TURN) {
    socket.emit("setTurn", action.turn, response => {});
  }

  if (action.type === gameTypes.SUBMIT_CLUE) {
    socket.emit("submitClue", action.clue, response => {
      mwApi.dispatch(gameOperations.submitClueSuccess(response));
    });
  }

  if (action.type === gameTypes.SUBMIT_GUESS) {
    socket.emit("submitGuess", action.guess, response => {});
  }

  return next(action);
};

export default socketMiddleware;
