import React from "react";
import ActivePlayer from "./ActivePlayer";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { fadeInDown, pulse } from "react-animations";

const useStyles = makeStyles(theme => ({
  hidden: {
    opacity: 0,
  },
  guess: {
    fontSize: "7rem",
  },
  word: {
    fontSize: "9rem",
  },
  blurryText: {
    color: "transparent",
    textShadow: `0 0 40px ${theme.palette.primary.contrastText}`,
  },
  blurInAnimation: {
    animation: "$blurAnimation-keyframes 1s ease-in-out 0.5s forwards",
  },
  fadeInAnimation: {
    opacity: 0,
    animation: "$fadeInAnimation-keyframes 1.5s forwards",
  },
  pulseAnimation: {
    opacity: 1,
    animation: "$pulseAnimation-keyframes 1.5s forwards",
  },
  "@keyframes blurAnimation-keyframes": {
    "0%": {
      textShadow: `0 0 40px ${theme.palette.primary.contrastText}`,
    },
    "100%": {
      textShadow: `0 0 0px ${theme.palette.primary.contrastText}`,
    },
  },
  "@keyframes fadeInAnimation-keyframes": fadeInDown,
  "@keyframes pulseAnimation-keyframes": pulse,
}));

const EvaluateAnswer = ({ word, activePlayer, guess }) => {
  const classes = useStyles();
  const [showWord, setShowWord] = React.useState(false);
  const [shimmer, setShimmer] = React.useState(false);
  const [shimmerWord, setShimmerWord] = React.useState(false);
  React.useEffect(() => {
    setTimeout(() => setShowWord(true), 2400);
    setTimeout(() => setShimmerWord(true), 4500);
    setTimeout(() => setShimmer(true), 4000);
  }, []);
  return (
    <>
      <ActivePlayer activePlayer={activePlayer} />
      <Typography className={classes.fadeInAnimation}>guessed...</Typography>
      <Typography
        variant={"h2"}
        className={clsx(
          classes.guess,
          classes.fadeInAnimation,
          shimmer && classes.pulseAnimation,
        )}
        style={{ animationDelay: "0.5s" }}
      >
        {guess}
      </Typography>
      <Typography
        className={classes.fadeInAnimation}
        style={{ animationDelay: "1s" }}
      >
        and the mystery word was...
      </Typography>
      <Typography
        variant={"h2"}
        className={clsx(
          classes.word,
          !shimmerWord && classes.blurryText,
          showWord && classes.blurInAnimation,
          shimmerWord && classes.pulseAnimation,
          !showWord && classes.fadeInAnimation,
        )}
        style={{ animationDelay: "1.25s" }}
      >
        {word}
      </Typography>
    </>
  );
};

export default EvaluateAnswer;
