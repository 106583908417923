import React from "react";
import OtherPlayers from "./OtherPlayers";
import MysteryWord from "./MysteryWord";
import ActivePlayer from "./ActivePlayer";
import RevealClues from "./RevealClues";

const HostScreen = ({
  word,
  activePlayer,
  players,
  isRevealed,
  sendPlayerStatus,
}) => {
  const allSubmitted = players.find(p => p.clue === null) === undefined;

  return (
    <>
      <MysteryWord word={word} isRevealed={isRevealed} />
      <ActivePlayer activePlayer={activePlayer} />
      {allSubmitted && <RevealClues players={players} />}
      {!allSubmitted && (
        <OtherPlayers players={players} sendPlayerStatus={sendPlayerStatus} />
      )}
    </>
  );
};

export default HostScreen;
