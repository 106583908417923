import types from "./types";
import gameStates from "../../constants/gameStates";
import gameSteps from "../../constants/gameSteps";
import { roomTypes } from "../room";

const initialState = {
  status: gameStates.PENDING_START,
  step: null,
  isHost: false,
  disclaimerAccepted: false,
  players: [],
  notifications: [],
};

const coreReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.BEGIN_GAME:
      return {
        ...state,
        status: gameStates.RUNNING,
      };
    case types.SET_MODE:
      return {
        ...state,
        mode: action.mode,
      };
    case types.DISCLAIMER_ACCEPTED:
      return {
        ...state,
        disclaimerAccepted: true,
      };
    //Host actions
    case roomTypes.CREATE_ROOM:
      return {
        ...state,
        status: gameStates.HOSTING_LOBBY,
        isHost: true,
      };
    //Player actions
    case types.JOIN_LOBBY:
      return {
        ...state,
        status: gameStates.PENDING_LOBBY,
        step: gameSteps.JOINING_ROOM,
      };
    case roomTypes.JOIN_ROOM_SUCCESS:
      return {
        ...state,
        step: gameSteps.PLAYER_SETUP,
      };
    case types.PLAYER_READY_SUCCESS:
      return {
        ...state,
        status: gameStates.PENDING_LOBBY,
        step: gameSteps.WAITING_START,
      };

    case types.ENQUEUE_SNACKBAR:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            key: action.key,
            ...action.notification,
          },
        ],
      };

    case types.CLOSE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.map(notification =>
          action.dismissAll || notification.key === action.key
            ? { ...notification, dismissed: true }
            : { ...notification },
        ),
      };

    case types.REMOVE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.filter(
          notification => notification.key !== action.key,
        ),
      };
    default:
      return state;
  }
};

export default coreReducer;
