const getAppState = state => {
  return state.core;
};

const getStep = state => {
  return state.core.step;
};

const isHost = state => {
  return state.core.isHost;
};

export default {
  getAppState,
  getStep,
  isHost,
};
