import React from "react";
import { connect } from "react-redux";
import { gameOperations, gameSelectors } from "../../modules/game";

const mapStateToProps = state => ({
  gameState: gameSelectors.getGameState(state),
});

const mapDispatchToProps = {
  initializeClients: gameOperations.initializeClients,
};

const InitializeClients = ({ gameState, initializeClients }) => {
  React.useEffect(() => {
    initializeClients(gameState);
  }, [gameState, initializeClients]);

  return null;
};

export default connect(mapStateToProps, mapDispatchToProps)(InitializeClients);
