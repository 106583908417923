import React from "react";
import { sample } from "lodash";
import { connect } from "react-redux";
import { gameOperations, gameSelectors } from "../../modules/game";

const mapStateToProps = state => ({
  players: gameSelectors.getPlayersWithClue(state),
});

const mapDispatchToProps = {
  setTurnOrder: gameOperations.setTurnOrder,
};

const SelectFirstPlayer = ({ players, setTurnOrder }) => {
  React.useEffect(() => {
    const playerIds = players.map(p => p.id);
    const firstPlayer = sample(playerIds);

    const firstPlayerIndex = playerIds.indexOf(firstPlayer);
    const turnOrder = [
      ...playerIds.slice(firstPlayerIndex, playerIds.length),
      ...playerIds.slice(0, firstPlayerIndex),
    ];

    setTurnOrder(turnOrder);
  }, [players, setTurnOrder]);

  return null;
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectFirstPlayer);
