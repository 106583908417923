import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { Button, Box } from "@material-ui/core";

const PlayerSetup = ({ playerReady }) => {
  const [playerName, setPlayerName] = useState();

  const handlePlayerReady = () => {
    const settings = { name: playerName };
    playerReady(settings);
  };

  return (
    <React.Fragment>
      <form
        noValidate
        autoComplete="off"
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <TextField
          id="playerName"
          label="Player Name"
          variant="outlined"
          onChange={e => setPlayerName(e.target.value)}
        />
      </form>
      <Box m={3} p={2}>
        <Button variant={"outlined"} size={"large"} onClick={handlePlayerReady}>
          Ready!
        </Button>
      </Box>
    </React.Fragment>
  );
};

export default PlayerSetup;
