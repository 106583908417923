import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  word: {
    fontSize: "10rem",
    marginTop: "-2rem",
  },
}));
const MysteryWord = ({ word, isRevealed }) => {
  const classes = useStyles();
  return (
    <>
      <Typography variant={"caption"}>Mystery Word</Typography>
      {!isRevealed && (
        <Typography variant={"h5"} className={classes.word}>
          ???
        </Typography>
      )}
      {isRevealed && (
        <Typography variant={"h2"} className={classes.word}>
          {word}
        </Typography>
      )}
    </>
  );
};

export default MysteryWord;
