import types from "./types";
import gameSteps from "../../constants/gameSteps";
import { roomTypes } from "../room";

const initialState = {
  step: gameSteps.SELECT_FIRST_PLAYER,
  turn: null,
  turnOrder: [],
  words: [],
  clues: {},
  guess: {},
};

const gameReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_WORDS:
      return {
        ...state,
        words: action.words,
      };
    case types.SET_TURN_ORDER:
      return {
        ...state,
        turnOrder: action.order,
        step: gameSteps.INITIALIZE_CLIENTS,
      };
    case types.SET_TURN:
      return {
        ...state,
        turn: action.turn,
      };
    case types.INITIALIZE_CLIENTS:
      return {
        ...state,
        step: gameSteps.PENDING_PLAYERS,
      };
    case types.SET_GAME_STATE: {
      return {
        ...state,
        turnOrder: action.gameState.turnOrder,
        words: action.gameState.words,
      };
    }
    case types.SEND_PLAYER_TURN:
      return {
        ...state,
        turn: action.turn,
        step:
          state.words.length === action.turn
            ? gameSteps.SUMMARY
            : gameSteps.PENDING_PLAYERS,
      };
    case types.SUBMIT_CLUE:
      return {
        ...state,
        step: gameSteps.PENDING_GUESS,
      };
    case types.SUBMIT_CLUE_SUCCESS:
    case types.SET_CLUES: {
      const { clue, playerId } = action.payload;
      return {
        ...state,
        clues: {
          ...state.clues,
          [state.turn]: {
            ...state.clues[state.turn],
            [playerId]: {
              playerId,
              clue: clue || "",
            },
          },
        },
      };
    }
    case types.SET_GUESS:
      return {
        ...state,
        step: gameSteps.EVALUATE_ANSWER,
        guess: {
          ...state.guess,
          [state.turn]: action.payload.guess,
        },
      };
    case roomTypes.PLAYER_RECONNECTED: {
      const { oldId, newId } = action.payload;
      const newClues = Object.keys(state.clues).reduce((acc, turn) => {
        const { [oldId]: deletedClue, ...rest } = state.clues[turn];
        if (deletedClue === undefined) {
          return { ...acc, [turn]: { ...rest } };
        }

        return {
          ...acc,
          [turn]: {
            ...rest,
            [newId]: {
              ...deletedClue,
              playerId: newId,
            },
          },
        };
      }, {});
      return {
        ...state,
        turnOrder: state.turnOrder.map(t => (t === oldId ? newId : t)),
        clues: newClues,
      };
    }
    case types.SYNC_CLIENT: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};

export default gameReducer;
