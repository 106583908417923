import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  text: {
    marginTop: "1.5em",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    flex: 1,
    marginTop: "-7em",
  },
}));

const GameOver = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography variant="h1" className={classes.text}>
        Game Over
      </Typography>
    </div>
  );
};

export default GameOver;
