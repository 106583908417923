const onlyOneDefinition = () => ({
  states: [
    {
      id: "start",
    },
    {
      id: "waitingForClues",
    },
    {
      id: "guessingWord",
    },
    {
      id: "providingClue",
    },
    {
      id: "waitingForGuess",
    },
    {
      id: "evaluating",
    },
  ],
  maxPlayers: 8,
  rounds: 2,
  words: [
    "Rap",
    "Chocolate",
    "Snow",
    "Tie",
    "Wind",
    "Crane",
    "Operation",
    "Rap",
    "Muse",
    "Defense",
    "King",
    "Ballet",
    "Band",
    "Alcohol",
    "Lava",
    "Tango",
    "Ripe",
    "Temple",
    "Sand",
    "Fries",
    "Towel",
    "Venus",
    "Octopus",
    "Cycle",
    "Opera",
    "Failure",
    "History",
    "Beer",
    "Disco",
    "President",
    "Prison",
    "Hole",
    "Punk",
    "Evening",
    "Map",
    "Neighbourhood",
    "Rock",
    "Tiger",
    "Needle",
    "Soap",
    "Reggae",
    "Manure",
    "Lake",
    "Monkey",
    "Lighthouse",
    "Cat",
    "Palace",
    "Election",
    "Honey",
    "Rambo",
    "Ladder",
    "Angel",
    "Mad",
    "Hair",
    "Matrix",
    "Barbecue",
    "Panda",
    "Dream",
    "Marriage",
    "Bellybutton",
    "Halloween",
    "Chip",
    "Baby",
    "Canteen",
    "Pair",
    "Club",
    "Christmas",
    "Fashion",
    "Station",
    "Lamp",
    "Oven",
    "Socket",
    "Easter",
    "Hose",
    "Rail",
    "Hammer",
    "Chile",
    "Gumbo",
    "Emperor",
    "Pope",
    "Cake",
    "Stallion",
    "Mexico",
    "White",
    "Bald",
    "Cavity",
    "Sleeve",
    "Gremlins",
    "Poker",
    "Pie",
    "Series",
    "Nest",
    "Spice",
    "Elephant",
    "Carnival",
    "Hannukah",
    "Elastic",
    "Pan",
    "Flash",
    "Tunnel",
    "Mirror",
    "Prom",
    "Bath",
    "Pit",
    "Fairy",
    "Mummy",
    "Battery",
    "Fair",
    "Karate",
    "Parrot",
    "Butterfly",
    "Powder",
    "Porcelain",
    "Market",
    "Cocoon",
    "Thunder",
    "Garden",
    "Painting",
    "Shack",
    "Truce",
    "String",
    "Australia",
    "Castle",
    "Guard",
    "Sheep",
    "Puppet",
    "Game",
    "Vegas",
    "Safe",
    "Plane",
    "Brain",
    "Mask",
    "Concert",
    "Troy",
    "Shark",
    "Board",
    "Fireman",
    "Glass",
    "Burrito",
    "Greece",
    "France",
    "Moon",
    "Cafeteria",
    "Handle",
    "Tool",
    "Polar",
    "Mouse",
    "Necklace",
    "Farm",
    "Belgium",
    "Godzilla",
    "Uniform",
    "Rain",
    "Fire",
    "Helmet",
    "Hollywood",
    "Mosquito",
    "Spear",
    "Purse",
    "End",
    "Iron",
    "Batman",
    "Song",
    "Nile",
    "Cinema",
    "Caterpillar",
    "Humour",
    "Corner",
    "Antarctica",
    "Sausage",
    "Sugar",
    "Theater",
    "Shovel",
    "Dune",
    "Pregnant",
    "Plastic",
    "Ray",
    "Carton",
    "Pebble",
    "Everest",
    "Terminator",
    "Letter",
    "Drag",
    "Paradise",
    "Egg",
    "House",
    "Godfather",
    "War",
    "Rope",
    "Wine",
    "Branch",
    "Cinderella",
    "Crepe",
    "Island",
    "Taxi",
    "Alcatraz",
    "Smoke",
    "Hazelnut",
    "Diamond",
    "Rose",
    "Number",
    "Decathon",
    "Rum",
    "Metal",
    "Tuna",
    "Vengeance",
    "Boss",
    "Emergency",
    "Cross",
    "Waltz",
    "Jackson",
    "Cigarette",
    "Bracelet",
    "Weather",
    "Tower",
    "Tattoo",
    "Spielberg",
    "Apple",
    "Siren",
    "Boxing",
    "Dwarf",
    "Circus",
    "Elvis",
    "Mower",
    "Stone",
    "Google",
    "Bow",
    "Mars",
    "Golf",
    "Ticket",
    "Myth",
    "Facebook",
    "Acorn",
    "Bone",
    "Bridge",
    "Canvas",
    "Vampire",
    "Monopoly",
    "Cartoon",
    "Hotel",
    "Mafia",
    "Large",
    "Missile",
    "Microsoft",
    "Ski",
    "Lonely",
    "Potato",
    "Wave",
    "School",
    "Lego",
    "Nintendo",
    "Bet",
    "Sale",
    "Manual",
    "Frost",
    "Register",
    "PlayStation",
    "Blond",
    "Iris",
    "Limb",
    "Rat",
    "Plier",
    "Amazon",
    "Tobacco",
    "Ruler",
    "Pump",
    "Aladdin",
    "Tube",
    "Belt",
    "Bar",
    "Mouth",
    "Carousel",
    "Psycho",
    "Grass",
    "Fall",
    "Doping",
    "Garlic",
    "Cube",
    "Rocky",
    "Milk",
    "Ice",
    "Flute",
    "Champagne",
    "Safari",
    "Alien",
    "Berry",
    "Study",
    "Gothic",
    "Titanic",
    "Machine",
    "Radio",
    "Pear",
    "Gladiator",
    "Sun",
    "Cereal",
    "Fox",
    "Police",
    "Simpson",
    "Lightning",
    "Newspaper",
    "Cocktail",
    "Mario",
    "Cork",
    "Violin",
    "Peach",
    "Avatar",
    "Mill",
    "Jungle",
    "Nun",
    "Firecracker",
    "Flintstone",
    "Robot",
    "Comedy",
    "Language",
    "Hairdresser",
    "Dinosaur",
    "Yellow",
    "Mushroom",
    "Pigeon",
    "Pikachu",
    "Syrup",
    "Forest",
    "Scale",
    "Zeus",
    "Cockroach",
    "Pirate",
    "Vacation",
    "Elf",
    "Magnet",
    "Fork",
    "Genius",
    "Dracula",
    "Lion",
    "Sock",
    "Friday",
    "Buffy",
    "Volcano",
    "Passion",
    "Rooster",
    "Electricity",
    "Baker",
    "Perfume",
    "Flame",
    "Zombie",
    "Joker",
    "Poison",
    "Star",
    "Wolf",
    "Jones",
    "Anniversary",
    "Ladybug",
    "Mustard",
    "Sherlock",
    "Bottle",
    "Virus",
    "Barbie",
    "Frankenstein",
    "Shelf",
    "Accent",
    "Shower",
    "Mountain",
    "Peanut",
    "Lightbulb",
    "Jedi",
    "Pimento",
    "Sombrero",
    "Clover",
    "Button",
    "Cheese",
    "Chewbacca",
    "Widowmaker",
    "Doctor",
    "Strawberry",
    "Nuclear",
    "Lottery",
    "Cemetery",
    "Cupid",
    "Umbrella",
    "Leap",
    "Root",
    "Treasure",
    "Pilot",
    "Mickey",
    "Sewer",
    "Galaxy",
    "Mustache",
    "Bubble",
    "Chain",
    "Stark",
    "Cookie",
    "Croissant",
    "Ghost",
    "Straw",
    "Nail",
    "Potter",
    "Tokyo",
    "Heel",
    "Chicken",
    "Helicopter",
    "Colonel",
    "Train",
    "Shrimp",
    "Room",
    "Cleopatra",
    "Window",
    "Grenade",
    "Stuffing",
    "Brush",
    "Pig",
    "Human",
    "Stew",
    "Canada",
    "Zoo",
    "Pipe",
    "Book",
    "Music",
    "Throat",
    "America",
    "Coffee",
    "Fever",
    "Knight",
    "Peace",
    "Flower",
    "Switzerland",
    "Calendar",
    "Slipper",
    "Leaf",
    "Cougar",
    "Revolution",
    "Sahara",
    "Darwin",
    "Tomato",
    "Parachute",
    "Cannon",
    "Binoculars",
    "Mirage",
    "Ramses",
    "Fire",
    "Crossroads",
    "Princess",
    "Ship",
    "Bowling",
    "Churchill",
    "Ram",
    "Spy",
    "Olympics",
    "Climb",
    "Lawyer",
    "Tolkien",
    "River",
    "Carpet",
    "Pony",
    "Crown",
    "New",
    "Tarantino",
    "Oprah",
    "Ninja",
    "Comforter",
    "Hunter",
    "Vegetable",
    "Grotto",
    "Ford",
    "Casino",
    "Candy",
    "Fountain",
    "Light",
    "Sofa",
    "Mozart",
    "Ring",
    "Pizza",
    "Guillotine",
    "Magician",
    "Hockey",
    "Banana",
    "Fitzgerald",
    "Caesar",
    "Noodle",
    "Hat",
    "Dentist",
    "Wheat",
    "Shell",
    "Shakespeare",
    "Giant",
    "Foam",
    "Cave",
    "Knife",
    "Pillow",
    "Armstrong",
    "Sword",
    "Flight",
    "Ticket",
    "Penguin",
    "Cell",
    "Gandhi",
    "Oasis",
    "Crocodile",
    "Jewelry",
    "Subway",
    "Glasses",
    "Sting",
    "Heart",
    "Moscow",
    "Pool",
    "Unicorn",
    "Orange",
    "Melon",
    "Anchor",
    "Israel",
    "Cactus",
    "Tennis",
    "Pepper",
    "Triangle",
    "Doll",
    "Italy",
    "Scene",
    "Screw",
    "Rake",
    "Computer",
    "Starbucks",
    "Ball",
    "Canvas",
    "Musketeer",
    "Thread",
    "Tulip",
    "IKEA",
    "Spartacus",
    "Fur",
    "Tornado",
    "Pyramid",
    "Alliance",
    "Sale",
    "Cheddar",
    "Shrek",
    "Pole",
    "Western",
    "Viking",
    "Alarm",
    "Dance",
    "Hulk",
    "Desert",
    "Goat",
    "Bread",
    "Devil",
    "Primary",
    "Tarzan",
    "Tradition",
    "Snake",
    "Cup",
    "Picasso",
    "Watch",
  ],
});

export default onlyOneDefinition;
