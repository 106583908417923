import React from "react";
import { Typography } from "@material-ui/core";
import { ClimbingBoxLoader } from "react-spinners";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  text: {
    marginTop: "1.5em",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    flex: 1,
    marginTop: "-7em",
  },
}));

const PendingClue = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <ClimbingBoxLoader loading={true} color={"#61dafb"} />
      <Typography variant="body1" className={classes.text}>
        Please wait while your team thinks of your clues
      </Typography>
    </div>
  );
};

export default PendingClue;
