import gameSteps from "../../constants/gameSteps";
import { roomSelectors } from "../room";

const getStep = state => {
  return state.game.step;
};

const getActivePlayerId = state => {
  return state.game.turnOrder[state.game.turn % state.game.turnOrder.length];
};

const getWord = state => {
  return state.game.turn >= 0 ? state.game.words[state.game.turn] : null;
};

const getGameState = state => {
  return state.game;
};

const getTurn = state => {
  return state.game.turn;
};

const getPlayerStep = state => {
  const turn = getTurn(state);
  if (turn === null) {
    return null;
  }
  if (turn >= state.game.words.length) {
    return gameSteps.GAME_OVER;
  }
  const myId = roomSelectors.getMyId(state);
  const totalPlayers = state.game.turnOrder.length;
  const currentPlayer = state.game.turnOrder[turn % totalPlayers];
  const cluesForThisTurn = state.game.clues[turn];

  if (currentPlayer === myId) {
    if (cluesForThisTurn === undefined) {
      return gameSteps.PENDING_CLUE;
    }

    const players = roomSelectors.getParticipants(state);
    const otherPlayers = players.filter(p => p.id !== myId);

    const allSubmitted =
      otherPlayers.find(p => !cluesForThisTurn.hasOwnProperty(p.id)) ===
      undefined;

    return allSubmitted ? gameSteps.GIVING_GUESS : gameSteps.PENDING_CLUE;
  }

  const me = roomSelectors.getMyId(state);

  if (cluesForThisTurn !== undefined && cluesForThisTurn.hasOwnProperty(me)) {
    return gameSteps.PENDING_GUESS;
  }

  return gameSteps.GIVING_CLUE;
};

const getPlayersWithClue = state => {
  const turn = getTurn(state);
  const cluesForThisTurn = state.game.clues[turn];
  const players = roomSelectors.getParticipants(state);
  const playersWithClues = players.map(p => {
    const clue =
      cluesForThisTurn !== undefined && cluesForThisTurn.hasOwnProperty(p.id)
        ? cluesForThisTurn[p.id].clue
        : null;
    const isNullified =
      cluesForThisTurn !== undefined && clue !== null
        ? isDuplicateClue(cluesForThisTurn, p.id, clue)
        : false;
    return {
      ...p,
      clue,
      isNullified,
    };
  });

  console.log(playersWithClues);
  return playersWithClues;
};

const getGuess = state => {
  const turn = getTurn(state);
  const guessForThisTurn = state.game.guess[turn];
  if (guessForThisTurn === undefined) {
    return null;
  }

  return guessForThisTurn;
};

const isDuplicateClue = (cluesForThisTurn, playerId, clue) => {
  const cluesArray = Object.keys(cluesForThisTurn)
    .filter(k => k !== playerId)
    .map(k => cluesForThisTurn[k]);
  return (
    cluesArray.find(c => c.clue.toLowerCase() === clue.toLowerCase()) !==
    undefined
  );
};

const getGuesses = state => {
  return state.game.guess;
};

const getWords = state => {
  return state.game.words;
};

const getScore = state => {
  const guesses = getGuesses(state);
  const words = getWords(state);
  let sum = 0;
  const turns = Object.keys(guesses);
  turns.forEach(t => {
    if (guesses[t].toLowerCase() === words[t].toLowerCase()) {
      sum++;
    }
  });
  return sum;
};

export default {
  getStep,
  getActivePlayerId,
  getWord,
  getGameState,
  getTurn,
  getPlayerStep,
  getPlayersWithClue,
  getGuess,
  getGuesses,
  getWords,
  getScore,
};
