import { connect } from "react-redux";
import App from "./App";
import { coreOperations, coreSelectors } from "../modules/core";

const mapStateToProps = state => ({
  appState: coreSelectors.getAppState(state),
  isHost: coreSelectors.isHost(state),
});

const mapDispatchToProps = dispatch => ({
  connectToServer: () => dispatch(coreOperations.initConnection()),
});

const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);
export default AppContainer;
