import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { Button, Box } from "@material-ui/core";

const PlayerLobby = ({ joinRoom }) => {
  const [roomNumber, setRoomNumber] = useState();
  const handleSubmit = e => {
    e.preventDefault();
    joinRoom(roomNumber);
  };

  return (
    <React.Fragment>
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <TextField
          id="roomNumberInput"
          label="Room #"
          variant="outlined"
          onChange={e => setRoomNumber(e.target.value)}
          type={"tel"}
          InputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          autoFocus
        />
      </form>
      <Box m={3} p={2}>
        <Button
          variant={"outlined"}
          size={"large"}
          onClick={() => joinRoom(roomNumber)}
        >
          Join
        </Button>
      </Box>
    </React.Fragment>
  );
};

export default PlayerLobby;
