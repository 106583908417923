import actions from "./actions";

const {
  startGame,
  startGameSuccess,
  startGameError,
  setMode,
  disclaimerAccepted,
  initConnection,
  initConnectionSuccess,
  initConnectionError,
  joinLobby,
  playerReady,
  playerReadySuccess,
  beginGame,
  enqueueSnackbar,
  closeSnackbar,
  removeSnackbar,
} = actions;

export default {
  startGame,
  startGameSuccess,
  startGameError,
  setMode,
  disclaimerAccepted,
  initConnection,
  initConnectionSuccess,
  initConnectionError,
  joinLobby,
  playerReady,
  playerReadySuccess,
  beginGame,
  enqueueSnackbar,
  closeSnackbar,
  removeSnackbar,
};
