import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Box } from "@material-ui/core";
import Logo from "../../components/Logo";

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: "94vh",
    display: "flex",
    fontSize: "calc(10px + 2vmin)",
    flexDirection: "column",
  },
  logoText: {
    textAlign: "center",
    flex: "0",
    marginTop: "-0.5em",
  },
  button: {
    flex: "1 1",
    minWidth: "12em",
  },
}));

const HomeScreen = ({ createRoom, joinLobby }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Box flex={"1.3 1"} />
      <Logo />
      <Box flex={"2 1"}>
        <div className={classes.logoText}>
          <Typography variant="h1" fontSize={19}>
            Playbox
          </Typography>
        </div>

        <Box m={3} flex={"1"}>
          <Button
            variant={"outlined"}
            size={"large"}
            onClick={createRoom}
            className={classes.button}
          >
            Create Game
          </Button>
          <Button
            variant={"outlined"}
            size={"large"}
            onClick={joinLobby}
            className={classes.button}
          >
            Join Game
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default HomeScreen;
