const CONNECT = "connect";
const ERROR = "error";
const RECONNECT = "reconnect";
const CREATE_ROOM = "createRoom";
const JOIN_ROOM = "joinRoom";
const PLAYER_JOINED = "playerJoined";
const PLAYER_READY = "playerReady";
const PLAYER_DISCONNECTED = "playerDisconnected";
const PLAYER_RECONNECTED = "playerRejoined";

/* Server to let clients know player game beginning */
const BEGIN_GAME = "beginGame";
/* Server to push game state to clients */
const INITIALIZE_CLIENT = "initializeClient";
/* Server to push current turn to all clients */
const SET_TURN = "setTurn";
/* Server to push clue a client submitted */
const CLUE_SUBMITTED = "clueSubmitted";
const GUESS_SUBMITTED = "guessSubmitted";
/* Server to let client know player is the active player */
const SET_PLAYER_ACTIVE = "setPlayerActive";
/* Server to let client know player is giving clues to active player */
const SET_PLAYER_OTHER = "setPlayerOther";

const SYNC_REQUEST = "syncRequest";
const SYNC_CLIENT = "syncClient";

export default {
  CONNECT,
  ERROR,
  RECONNECT,
  CREATE_ROOM,
  JOIN_ROOM,
  PLAYER_JOINED,
  PLAYER_READY,
  PLAYER_DISCONNECTED,
  PLAYER_RECONNECTED,
  BEGIN_GAME,
  INITIALIZE_CLIENT,
  SET_TURN,
  CLUE_SUBMITTED,
  GUESS_SUBMITTED,
  SET_PLAYER_ACTIVE,
  SET_PLAYER_OTHER,
  SYNC_REQUEST,
  SYNC_CLIENT,
};
