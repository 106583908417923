const CREATE_ROOM = "app/room/create_room";
const CREATE_ROOM_SUCCESS = "app/room/create_room_success";
const CREATE_ROOM_ERROR = "app/room/create_room_error";
const JOIN_ROOM = "app/room/join_room";
const JOIN_ROOM_SUCCESS = "app/room/join_room_success";
const JOIN_ROOM_ERROR = "app/room/join_room_error";

const PLAYER_JOINED = "app/room/player_joined";
const PLAYER_DISCONNECTED = "app/room/player_disconnected";
const PLAYER_IS_READY = "app/rooms/player_is_ready";

const PLAYER_RECONNECTED = "app/room/player_reconnected";

export default {
  CREATE_ROOM,
  CREATE_ROOM_SUCCESS,
  CREATE_ROOM_ERROR,
  JOIN_ROOM,
  JOIN_ROOM_SUCCESS,
  JOIN_ROOM_ERROR,
  PLAYER_JOINED,
  PLAYER_DISCONNECTED,
  PLAYER_IS_READY,
  PLAYER_RECONNECTED,
};
