import { combineReducers } from "redux";
import core from "./core";
import room from "./room";
import game from "./game";

export default combineReducers({
  core,
  room,
  game,
});
