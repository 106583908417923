const SET_WORDS = "app/game/set_words";
const SET_TURN_ORDER = "app/game/set_turn_order";
const SET_GAME_STATE = "app/game/set_game_state";

const SET_TURN = "app/game/set_turn";
const SET_CLUES = "app/game/set_clues";
const SET_GUESS = "app/game/set_guess";

const SUBMIT_CLUE = "app/game/submit_clue";
const SUBMIT_CLUE_SUCCESS = "app/game/submit_clue_success";
const SUBMIT_CLUE_ERROR = "app/game/submit_clue_error";
const SUBMIT_GUESS = "app/core/submit_guess";

const SEND_PLAYER_TURN = "app/game/send_player_turn";
const SEND_PLAYER_TURN_SUCCESS = "app/game/send_player_turn_success";
const SEND_PLAYER_TURN_ERROR = "app/game/send_player_turn_error";

const INITIALIZE_CLIENTS = "app/game/initialize_clients";
const INITIALIZE_CLIENTS_SUCCESS = "app/game/initialize_clients_success";
const INITIALIZE_CLIENTS_ERROR = "app/game/initialize_clients_error";

const SYNC_CLIENT = "app/game/sync_client";

export default {
  SET_WORDS,
  SET_GAME_STATE,
  SET_TURN,
  SET_CLUES,
  SET_GUESS,
  SUBMIT_CLUE,
  SUBMIT_CLUE_SUCCESS,
  SUBMIT_CLUE_ERROR,
  SUBMIT_GUESS,
  SET_TURN_ORDER,
  SEND_PLAYER_TURN,
  SEND_PLAYER_TURN_SUCCESS,
  SEND_PLAYER_TURN_ERROR,
  INITIALIZE_CLIENTS,
  INITIALIZE_CLIENTS_SUCCESS,
  INITIALIZE_CLIENTS_ERROR,
  SYNC_CLIENT,
};
