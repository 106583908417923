import types from "./types";

const startGame = () => {
  return {
    type: types.START_GAME,
  };
};

const startGameSuccess = () => {
  return {
    type: types.START_GAME_SUCCESS,
  };
};

const startGameError = error => {
  return {
    type: types.START_GAME_ERROR,
    error,
  };
};

const setMode = mode => {
  return {
    type: types.SET_MODE,
    mode,
  };
};

const disclaimerAccepted = () => {
  return {
    type: types.DISCLAIMER_ACCEPTED,
  };
};

const initConnection = () => {
  return {
    type: types.INIT_CONNECTION,
  };
};

const initConnectionSuccess = () => {
  return {
    type: types.INIT_CONNECTION_SUCCESS,
  };
};

const initConnectionError = error => {
  return {
    type: types.INIT_CONNECTION_ERROR,
    error,
  };
};

const joinLobby = () => {
  return {
    type: types.JOIN_LOBBY,
  };
};

const playerReady = playerSettings => {
  return {
    type: types.PLAYER_READY,
    playerSettings,
  };
};

const playerReadySuccess = payload => {
  return {
    type: types.PLAYER_READY_SUCCESS,
    payload,
  };
};

const beginGame = payload => {
  return {
    type: types.BEGIN_GAME,
    payload,
  };
};

const enqueueSnackbar = notification => {
  const key = notification.options && notification.options.key;

  return {
    type: types.ENQUEUE_SNACKBAR,
    notification: {
      ...notification,
      key: key || new Date().getTime() + Math.random(),
    },
  };
};

const closeSnackbar = key => ({
  type: types.CLOSE_SNACKBAR,
  dismissAll: !key, // dismiss all if no key has been defined
  key,
});

const removeSnackbar = key => ({
  type: types.REMOVE_SNACKBAR,
  key,
});

export default {
  startGame,
  startGameSuccess,
  startGameError,
  setMode,
  disclaimerAccepted,
  initConnection,
  initConnectionSuccess,
  initConnectionError,
  joinLobby,
  playerReady,
  playerReadySuccess,
  beginGame,
  enqueueSnackbar,
  closeSnackbar,
  removeSnackbar,
};
