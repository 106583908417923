import { connect } from "react-redux";
import PlayerLobbyScreen from "./PlayerLobbyScreen";
import { coreSelectors, coreOperations } from "../../modules/core";
import { roomOperations } from "../../modules/room";

const mapStateToProps = state => ({
  step: coreSelectors.getStep(state),
});
const mapDispatchToProps = dispatch => ({
  joinRoom: roomNumber => dispatch(roomOperations.joinRoom(roomNumber)),
  playerReady: playerSettings =>
    dispatch(coreOperations.playerReady(playerSettings)),
  startGame: () => dispatch(coreOperations.startGame()),
});

const PlayerLobbyScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PlayerLobbyScreen);
export default PlayerLobbyScreenContainer;
