import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PendingClue from "./PendingClue";
import PendingGuess from "./PendingGuess";
import steps from "../../constants/gameSteps";
import GiveClue from "./GiveClue";
import GiveGuess from "./GiveGuess";
import GameOver from "./GameOver";

const useStyles = makeStyles(theme => ({
  root: {
    //backgroundColor: "#337733",
    minHeight: "94vh",
    display: "flex",
    flexDirection: "column",
    //justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    //marginTop: "-3em",
    //height: "100%",
    flex: "1 0",
  },
}));

const ClientScreen = ({ step, word, submitGuess, submitClue }) => {
  const classes = useStyles();
  //step = steps.PENDING_CLUE;

  return (
    <div className={classes.root}>
      {step === steps.GIVING_CLUE && (
        <GiveClue word={word} submitClue={submitClue} />
      )}
      {step === steps.PENDING_GUESS && <PendingGuess />}
      {step === steps.PENDING_CLUE && <PendingClue />}
      {step === steps.GIVING_GUESS && <GiveGuess submitGuess={submitGuess} />}
      {step === steps.GAME_OVER && <GameOver />}
    </div>
  );
};

export default ClientScreen;
