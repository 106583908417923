import React from "react";
import Confetti from "react-dom-confetti";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  leftSide: {
    left: 0,
    bottom: "5rem",
    position: "absolute",
  },
  rightSide: {
    right: 0,
    bottom: "5rem",
    position: "absolute",
  },
}));

const defaultConfig = {
  angle: "45",
  spread: 45,
  startVelocity: "50",
  elementCount: 50,
  dragFriction: 0.1,
  duration: 3000,
  stagger: 0,
  width: "10px",
  height: "10px",
  colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
};

const SideConfetti = ({ active, side = "left", config }) => {
  const classes = useStyles();

  const mergedConfig = {
    ...defaultConfig,
    ...config,
  };

  console.log(side);
  return (
    <div
      className={clsx(
        side === "left" && classes.leftSide,
        side === "right" && classes.rightSide,
      )}
    >
      <Confetti active={active} config={mergedConfig} />
    </div>
  );
};

export default SideConfetti;
