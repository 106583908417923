import { IconButton, InputAdornment, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";
import React from "react";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

const useStyles = makeStyles(theme => ({
  textField: {
    marginTop: "1.5em",
  },
}));

const GiveClue = ({ word, submitClue }) => {
  const classes = useStyles();
  const [clue, setClue] = React.useState("");

  const handleSubmit = e => {
    e.preventDefault();
    submitClue(clue);
  };

  return (
    <>
      <Typography variant="h3" fontSize={10}>
        {word}
      </Typography>
      <Typography variant="caption">
        Think of a single word clue to get the active player to guess "{word}"
      </Typography>

      <ValidatorForm onSubmit={handleSubmit}>
        <TextValidator
          id="clueTextField"
          label="Your clue"
          variant="outlined"
          onChange={e => setClue(e.target.value.trim())}
          className={classes.textField}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton type={"submit"}>
                  <KeyboardReturnIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          validators={["required", "matchRegexp:^\\w+$"]}
          errorMessages={["clue is required", "clue can only be one word"]}
          value={clue}
          fullWidth
        />
      </ValidatorForm>
    </>
  );
};

export default GiveClue;
