import { connect } from "react-redux";
import SummaryScreen from "./SummaryScreen";
import { gameOperations, gameSelectors } from "../../modules/game";

const mapStateToProps = state => ({
  score: gameSelectors.getScore(state),
});

const mapDispatchToProps = {
  restartGame: gameOperations.sendPlayerTurn,
};

const SummaryScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SummaryScreen);
export default SummaryScreenContainer;
