import types from "./types";

const setWords = words => {
  return {
    type: types.SET_WORDS,
    words,
  };
};

const setGameState = gameState => {
  return {
    type: types.SET_GAME_STATE,
    gameState,
  };
};

const setTurn = turn => {
  return {
    type: types.SET_TURN,
    turn,
  };
};

const submitClue = clue => {
  return {
    type: types.SUBMIT_CLUE,
    clue,
  };
};

const submitClueSuccess = payload => {
  return {
    type: types.SUBMIT_CLUE_SUCCESS,
    payload,
  };
};

const submitClueError = payload => {
  return {
    type: types.SUBMIT_CLUE_ERROR,
    payload,
  };
};

const submitGuess = guess => {
  return {
    type: types.SUBMIT_GUESS,
    guess,
  };
};

const setTurnOrder = order => {
  return {
    type: types.SET_TURN_ORDER,
    order,
  };
};

const setClues = payload => {
  return {
    type: types.SET_CLUES,
    payload,
  };
};

const setGuess = payload => {
  return {
    type: types.SET_GUESS,
    payload,
  };
};

const syncClient = payload => {
  return {
    type: types.SYNC_CLIENT,
    payload,
  };
};

export default {
  setWords,
  setGameState,
  submitClue,
  submitClueSuccess,
  submitClueError,
  submitGuess,
  setTurnOrder,
  setTurn,
  setClues,
  setGuess,
  syncClient,
};
