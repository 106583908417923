import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import steps from "../../constants/gameSteps";
import HostScreen from "./HostScreen";
import SelectFirstPlayer from "./SelectFirstPlayer";
import InitializeClients from "./InitializeClients";
import EvaluateAnswer from "./EvaluateAnswer";
import Timer from "../../components/BottomCornerTimer";
import SummaryScreenContainer from "./SummaryScreenContainer";

const useStyles = makeStyles(theme => ({
  root: {
    //backgroundColor: "#337733",
    minHeight: "94vh",
    display: "flex",
    flexDirection: "column",
    //justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    //marginTop: "-3em",
    //height: "100%",
    flex: "1 0",
  },
}));

const GameScreen = ({
  activePlayerId,
  players,
  step,
  word,
  turn,
  guess,
  setTurn,
}) => {
  const classes = useStyles();
  const [showTimer, setShowTimer] = React.useState(false);

  React.useEffect(() => {
    if (step === steps.PENDING_PLAYERS && turn === null) {
      setTurn(0);
    }
  }, [setTurn, step, turn]);

  React.useEffect(() => {
    if (step === steps.EVALUATE_ANSWER) {
      setTimeout(() => {
        setShowTimer(true);
        setTimeout(() => setTurn(turn + 1), 5000);
      }, 4000);
    } else {
      setShowTimer(false);
    }
  }, [setTurn, step, turn]);

  //step = steps.SUMMARY;
  // //activePlayer = "Chris";
  // players = [
  //   { id: 1, name: "Test 1", clue: "the clue", isNullified: true },
  //   { id: 2, name: "Test 2", clue: "the clue" },
  //   { id: 3, name: "Test 3", clue: "the clue" },
  //   { id: 4, name: "Test 4", clue: "the clue" },
  //   { id: 5, name: "Test 5", clue: "the clue" },
  //   { id: 6, name: "Test 6", clue: "the clue" },
  //   { id: 7, name: "Test 7", clue: "the clue" },
  // ];

  const activePlayerObject = players.find(p => p.id === activePlayerId);
  const activePlayer =
    activePlayerObject !== undefined ? activePlayerObject.name : activePlayerId;

  const otherPlayers = players.filter(p => p.id !== activePlayerId);
  return (
    <div className={classes.root}>
      {step === steps.SELECT_FIRST_PLAYER && <SelectFirstPlayer />}
      {step === steps.INITIALIZE_CLIENTS && <InitializeClients />}
      {step === steps.PENDING_PLAYERS && (
        <HostScreen
          activePlayer={activePlayer}
          word={word}
          isRevealed={false}
          players={otherPlayers}
          sendPlayerStatus={setTurn}
        />
      )}
      {step === steps.EVALUATE_ANSWER && (
        <EvaluateAnswer word={word} activePlayer={activePlayer} guess={guess} />
      )}
      {step === steps.SUMMARY && <SummaryScreenContainer />}
      {showTimer && <Timer />}
    </div>
  );
};

export default GameScreen;
