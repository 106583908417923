import types from "./types";

const createRoom = () => {
  return {
    type: types.CREATE_ROOM,
  };
};

const createRoomSuccess = payload => {
  return {
    type: types.CREATE_ROOM_SUCCESS,
    payload,
  };
};

const createRoomError = error => ({
  type: types.CREATE_ROOM_ERROR,
  error,
});

const joinRoom = roomNumber => ({
  type: types.JOIN_ROOM,
  roomNumber,
});

const joinRoomSuccess = payload => ({
  type: types.JOIN_ROOM_SUCCESS,
  payload,
});

const joinRoomError = error => ({
  type: types.JOIN_ROOM_ERROR,
  error,
});

const playerJoined = payload => ({
  type: types.PLAYER_JOINED,
  payload,
});

const playerDisconnected = payload => ({
  type: types.PLAYER_DISCONNECTED,
  payload,
});

const playerIsReady = payload => ({
  type: types.PLAYER_IS_READY,
  payload,
});

const playerReconnected = payload => ({
  type: types.PLAYER_RECONNECTED,
  payload,
});

export default {
  createRoom,
  createRoomSuccess,
  createRoomError,
  joinRoom,
  joinRoomSuccess,
  joinRoomError,
  playerJoined,
  playerDisconnected,
  playerIsReady,
  playerReconnected,
};
