import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  player: {
    fontSize: "5rem",
  },
}));

const ActivePlayer = ({ activePlayer }) => {
  const classes = useStyles();
  return (
    <>
      <Typography variant={"caption"}>Active Player</Typography>
      <Typography variant={"h5"} className={classes.player}>
        {activePlayer}
      </Typography>
    </>
  );
};

export default ActivePlayer;
