import React from "react";
import { List, ListItem, Avatar, Card, CardHeader } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  card: {
    backgroundColor: "#383c44",
    flex: "1",
  },
  pulseAnimation: {
    animation: "$pulseAnimation-keyframes 1.5s ease-in-out 0.5s infinite",
  },
  "@keyframes pulseAnimation-keyframes": {
    "0%": {
      opacity: 1,
    },
    "50%": {
      opacity: 0.4,
    },
    "100%": {
      opacity: 1,
    },
  },
}));

const PlayerList = ({ playerList }) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <List>
        {playerList.map((player, index) => {
          return (
            <ListItem key={player.id + index}>
              <Card
                className={clsx(
                  classes.card,
                  !player.name && classes.pulseAnimation,
                )}
              >
                <CardHeader
                  avatar={
                    player.name === null ? (
                      <Skeleton variant="circle" width={40} height={40} />
                    ) : (
                      <Avatar>{player.name.charAt(0)}</Avatar>
                    )
                  }
                  title={
                    player.name === null ? `Player ${index + 1}` : player.name
                  }
                  titleTypographyProps={{ variant: "h5" }}
                />
              </Card>
            </ListItem>
          );
        })}
      </List>
    </React.Fragment>
  );
};

export default PlayerList;
