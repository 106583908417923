import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./index.css";
import AppContainer from "./pages/AppContainer";
import * as serviceWorker from "./serviceWorker";
import store from "./store";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";
import Logging from "./utils/Logging";

if (process.env.NODE_ENV !== "development") {
  Logging.init();
}

const theme = createMuiTheme({
  palette: {
    type: "dark",
    background: {
      default: "#282c34",
    },
  },
  overrides: {
    MuiSnackbarContent: {
      root: {
        backgroundColor: "transparent",
        color: "white",
      },
    },
  },
});

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <AppContainer />
      </SnackbarProvider>
    </ThemeProvider>
  </Provider>,
  document.getElementById("root"),
);
serviceWorker.unregister();
