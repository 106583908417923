import { coreTypes, coreSelectors } from "../modules/core";
import { gameOperations } from "../modules/game";
import { roomSelectors } from "../modules/room";
import { sampleSize } from "lodash";
import gameDefinition from "../assets/onlyOne.definition";

// const selectFirstPlayer = players => {
//   return sample(players);
// };

const selectGameWords = (words, players, rounds) => {
  return sampleSize(words, 13);
};

const gameInitializationMiddleware = mwApi => next => action => {
  const state = mwApi.getState();

  if (action.type === coreTypes.BEGIN_GAME && coreSelectors.isHost(state)) {
    console.log("game start middlware");
    const participants = roomSelectors.getParticipants(state);
    //const firstPlayer = selectFirstPlayer(participants);
    const words = selectGameWords(
      gameDefinition().words,
      participants.length,
      2,
    );

    //console.log(firstPlayer);
    console.log(words);

    mwApi.dispatch(gameOperations.setWords(words));
    //mwApi.dispatch(gameOperations.set)
    //const poses = posesSelectors.getPoses(state);
    //const selectedPoses = selectRandomPoses(poses);
    //mwApi.dispatch(gameOperations.setLevels(selectedPoses));
  }
  return next(action);
};

export default gameInitializationMiddleware;
