const PENDING_START = "game/pending_start";
const PENDING_LOBBY = "game/pending_lobby";
const HOSTING_LOBBY = "game/hosting_lobby";
const RUNNING = "game/running";

export default {
  PENDING_START,
  PENDING_LOBBY,
  HOSTING_LOBBY,
  RUNNING,
};
