import React from "react";
import { Typography } from "@material-ui/core";
import PlayerClueCard from "./PlayerClueCard";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    flex: "1",
    width: "100vw",
  },
  cardContainer: {
    display: "flex",
    flex: "1",
    justifyContent: "center",
  },
  clear: {
    clear: "both",
  },
}));

const MAX_PLAYERS_PER_ROW = 4;

const OtherPlayers = ({ players }) => {
  const classes = useStyles();

  const renderPlayers = () => {
    const rowComponents = [];
    const maxRows = Math.floor(players.length / MAX_PLAYERS_PER_ROW);
    for (var rows = 0; rows <= maxRows; rows++) {
      const playerCards = [];
      for (var cols = 0; cols < MAX_PLAYERS_PER_ROW; cols++) {
        const playerIndex = cols + rows * MAX_PLAYERS_PER_ROW;
        if (playerIndex >= players.length) {
          break;
        }
        const { clue } = players[playerIndex];
        playerCards.push(
          <PlayerClueCard
            key={players[playerIndex].id}
            player={players[playerIndex]}
            clue={clue}
          />,
        );
      }
      rowComponents.push(
        <div className={classes.cardContainer} key={`row-${rows}`}>
          {playerCards}
        </div>,
      );
    }

    return rowComponents;
  };

  return (
    <div>
      <Typography variant={"caption"}>The Team</Typography>
      <div className={classes.container}>{renderPlayers()}</div>
    </div>
  );
};

export default OtherPlayers;
