const PLAYER_SETUP = "game/pending_lobby/player_setup";
const JOINING_ROOM = "game/pending_lobby/joining_room";
const WAITING_START = "game/pending_lobby/waiting_start";

const SELECT_FIRST_PLAYER = "game/setup/select_first_player";
const INITIALIZE_CLIENTS = "game/setup/initialize_clients";
const PENDING_PLAYERS = "game/running/pending_players";
const EVALUATE_ANSWER = "game/running/evaluate_answer";

const GIVING_CLUE = "game/running/giving_clue";
const GIVING_GUESS = "game/running/giving_guess";
const PENDING_CLUE = "game/running/pending_clue";
const PENDING_GUESS = "game/running/pending_guess";

const SUMMARY = "game/running/summary";
const GAME_OVER = "game/running/game_over";

export default {
  PLAYER_SETUP,
  JOINING_ROOM,
  WAITING_START,
  SELECT_FIRST_PLAYER,
  INITIALIZE_CLIENTS,
  PENDING_PLAYERS,
  EVALUATE_ANSWER,
  GIVING_CLUE,
  GIVING_GUESS,
  PENDING_CLUE,
  PENDING_GUESS,
  SUMMARY,
  GAME_OVER,
};
