import actions from "./actions";

const {
  createRoom,
  createRoomSuccess,
  createRoomError,
  joinRoom,
  joinRoomSuccess,
  joinRoomError,
  playerJoined,
  playerDisconnected,
  playerIsReady,
  playerReconnected,
} = actions;

export default {
  createRoom,
  createRoomSuccess,
  createRoomError,
  joinRoom,
  joinRoomSuccess,
  joinRoomError,
  playerJoined,
  playerDisconnected,
  playerIsReady,
  playerReconnected,
};
