import { connect } from "react-redux";
import GameScreen from "./GameScreen";
import { gameOperations, gameSelectors } from "../../modules/game";

const mapStateToProps = state => ({
  activePlayerId: gameSelectors.getActivePlayerId(state),
  players: gameSelectors.getPlayersWithClue(state),
  step: gameSelectors.getStep(state),
  word: gameSelectors.getWord(state),
  turn: gameSelectors.getTurn(state),
  guess: gameSelectors.getGuess(state),
});

const mapDispatchToProps = {
  setTurn: gameOperations.sendPlayerTurn,
};

const GameScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(GameScreen);
export default GameScreenContainer;
