import types from "./types";
import { coreTypes } from "../core";

const initialState = {
  status: "init",
  me: null,
  roomNumber: null,
  participants: [],
  players: {},
};

const roomReducer = (state = initialState, action) => {
  switch (action.type) {
    // Host actions
    case types.CREATE_ROOM:
      return {
        ...state,
        status: "CREATING",
      };
    case types.SET_MODE:
      return {
        ...state,
        mode: action.mode,
      };
    // Player actions
    case types.CREATE_ROOM_SUCCESS:
      return {
        ...state,
        roomNumber: action.payload.room,
      };
    case types.JOIN_ROOM_SUCCESS:
      return {
        ...state,
        roomNumber: action.payload.metadata.room,
        participants: action.payload.metadata.participants,
        players: action.payload.metadata.players,
        me: action.payload.metadata.playerId,
      };
    // Other player actions
    case types.PLAYER_JOINED:
      return {
        ...state,
        participants: [...state.participants, action.payload.playerId],
      };
    case types.PLAYER_IS_READY:
      return {
        ...state,
        players: {
          ...state.players,
          [action.payload.id]: {
            ...action.payload,
            status: "connected",
          },
        },
      };
    case types.PLAYER_DISCONNECTED: {
      const { [action.payload.id]: disconnected } = state.players;
      if (disconnected === null || disconnected === undefined) {
        return state;
      }
      return {
        ...state,
        players: {
          ...state.players,
          [disconnected.id]: {
            ...state.players[disconnected.id],
            status: "disconnected",
          },
        },
      };
    }
    case types.PLAYER_RECONNECTED: {
      console.log(action);
      const { oldId, newId } = action.payload;
      const { [oldId]: deleted, ...rest } = state.players;
      return {
        ...state,
        me: state.me === oldId ? newId : state.me,
        participants: state.participants.map(p => (p === oldId ? newId : p)),
        players: {
          ...rest,
          [newId]: {
            ...deleted,
            id: newId,
            status: "connected",
          },
        },
      };
    }
    case coreTypes.PLAYER_READY_SUCCESS: {
      return {
        ...state,
        players: {
          ...state.players,
          [action.payload.playerMetadata.id]: {
            ...state.players[action.payload.playerMetadata.id],
            ...action.payload.playerMetadata,
          },
        },
      };
    }
    default:
      return state;
  }
};

export default roomReducer;
