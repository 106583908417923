import React from "react";
import { Typography } from "@material-ui/core";
import SideConfetti from "../../components/SideConfetti";

const SummaryScreen = ({ score }) => {
  const [leftConfettiActive, setLeftConfettiActive] = React.useState(false);
  const [rightConfettiActive, setRightConfettiActive] = React.useState(false);

  React.useEffect(() => {
    setLeftConfettiActive(true);
    const time = Math.random() * 2000;
    let timer1 = setTimeout(() => setLeftConfettiActive(false), 5000 - time);
    return () => {
      clearTimeout(timer1);
    };
  }, [leftConfettiActive]);

  React.useEffect(() => {
    setRightConfettiActive(true);
    const time = Math.random() * 2000;
    let timer1 = setTimeout(() => setRightConfettiActive(false), 5000 - time);
    return () => {
      clearTimeout(timer1);
    };
  }, [rightConfettiActive]);

  return (
    <>
      <Typography variant={"h1"}>Congratulations!</Typography>
      <Typography variant={"h2"}>You got {score} points!</Typography>

      <SideConfetti active={leftConfettiActive} side="left" />

      <SideConfetti
        active={rightConfettiActive}
        side="right"
        config={{ angle: 135 }}
      />
    </>
  );
};

export default SummaryScreen;
