import {
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";
import React from "react";

const useStyles = makeStyles(theme => ({
  textField: {
    marginTop: "1.5em",
  },
}));

const GiveGuess = ({ submitGuess }) => {
  const classes = useStyles();
  const [guess, setGuess] = React.useState();
  const [submitted, setSubmitted] = React.useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    setSubmitted(true);
    submitGuess(guess);
  };

  return (
    <>
      <Typography variant="caption">
        Refer to the screen for your clues to guess the mystery word. Good luck!
      </Typography>

      <form onSubmit={handleSubmit}>
        <TextField
          id="guessTextField"
          label="Your guess"
          variant="outlined"
          onChange={e => setGuess(e.target.value)}
          className={classes.textField}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton type={"submit"}>
                  <KeyboardReturnIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          disabled={submitted}
          fullWidth
        />
      </form>
    </>
  );
};

export default GiveGuess;
