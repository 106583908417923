import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import BoxIcon from "./icons/BoxIcon";

const useStyles = makeStyles(theme => ({
  logo: {
    flex: "0 0 10%",
  },
}));

const Logo = () => {
  const classes = useStyles();
  return (
    <div className={classes.logo}>
      <BoxIcon fill={"#61dafb"} width={"5em"} />
    </div>
  );
};

export default Logo;
