import { createStore, applyMiddleware } from "redux";
import rootReducer from "./modules";
import { composeWithDevTools } from "redux-devtools-extension";
import gameInitializationMiddleware from "./middlewares/gameInitializationMiddleware";
import socketMiddleware from "./middlewares/socketMiddleware";
import LogRocket from "logrocket";

const store = createStore(
  rootReducer,
  undefined,
  composeWithDevTools(
    applyMiddleware(
      gameInitializationMiddleware,
      socketMiddleware,
      LogRocket.reduxMiddleware(),
    ),
  ),
);

export default store;
