import { connect } from "react-redux";
import HostingLobbyScreen from "./HostingLobbyScreen";
import { roomOperations, roomSelectors } from "../../modules/room";

const mapStateToProps = state => ({
  roomNumber: roomSelectors.getRoomNumber(state),
  players: roomSelectors.getParticipants(state)
});
const mapDispatchToProps = dispatch => ({
  createRoom: () => dispatch(roomOperations.createRoom())
});

const HostingLobbyScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HostingLobbyScreen);
export default HostingLobbyScreenContainer;
