import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import HomeScreenContainer from "./home/HomeScreenContainer";
import HostingLobbyScreenContainer from "./lobby/HostingLobbyScreenContainer";
import PlayerLobbyScreenContainer from "./lobby/PlayerLobbyScreenContainer";
import GameScreenContainer from "./game/GameScreenContainer";
import ClientScreenContainer from "./client/ClientScreenContainer";
import gameStates from "../constants/gameStates";
import Notifier from "../components/Notifier";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    textAlign: "center",
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    flex: "1",
  },
}));

const App = ({ appState, isHost, connectToServer }) => {
  const classes = useStyles();
  const { status } = appState;
  useEffect(() => {
    if (status === gameStates.PENDING_START) {
      connectToServer();
    }
  }, [connectToServer, status]);

  // const status = gameStates.RUNNING;
  // isHost = true; <div style={{ color: "white" }}>{JSON.stringify(theState)}</div>
  return (
    <div className={classes.root}>
      {status === gameStates.PENDING_START && <HomeScreenContainer />}
      {status === gameStates.HOSTING_LOBBY && <HostingLobbyScreenContainer />}
      {status === gameStates.PENDING_LOBBY && <PlayerLobbyScreenContainer />}
      {status === gameStates.RUNNING && isHost && <GameScreenContainer />}
      {status === gameStates.RUNNING && !isHost && <ClientScreenContainer />}
      <Notifier />
    </div>
  );
};

export default App;
