const START_GAME = "app/core/start_game";
const START_GAME_SUCCESS = "app/core/start_game_success";
const START_GAME_ERROR = "app/core/start_game_error";
const SET_MODE = "app/core/set_mode";
const DISCLAIMER_ACCEPTED = "app/core/disclaimer_accepted";
const INIT_CONNECTION = "app/core/init_connection";
const INIT_CONNECTION_SUCCESS = "app/core/init_connection_success";
const INIT_CONNECTION_ERROR = "app/core/init_connection_error";
const JOIN_LOBBY = "app/core/join_lobby";
const PLAYER_READY = "app/core/player_ready";
const PLAYER_READY_SUCCESS = "app/core/player_ready_success";

const BEGIN_GAME = "app/core/begin_game";

const ENQUEUE_SNACKBAR = "app/core/ENQUEUE_SNACKBAR";
const CLOSE_SNACKBAR = "app/core/CLOSE_SNACKBAR";
const REMOVE_SNACKBAR = "app/coreREMOVE_SNACKBAR";

export default {
  START_GAME,
  START_GAME_SUCCESS,
  START_GAME_ERROR,
  SET_MODE,
  DISCLAIMER_ACCEPTED,
  INIT_CONNECTION,
  INIT_CONNECTION_SUCCESS,
  INIT_CONNECTION_ERROR,
  JOIN_LOBBY,
  PLAYER_READY,
  PLAYER_READY_SUCCESS,
  BEGIN_GAME,
  ENQUEUE_SNACKBAR,
  CLOSE_SNACKBAR,
  REMOVE_SNACKBAR,
};
