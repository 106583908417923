import React from "react";
import { Card, CardHeader, Avatar, Popper } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  card: {
    margin: "1em",
    float: "left",
    minWidth: "8em",
  },
  clue: {
    fontSize: "2rem",
  },
  popper: {
    zIndex: 1,
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: "-0.9em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "0 1em 1em 1em",
      },
    },
  },
  popperClue: {
    '&[x-placement*="bottom"] $arrow': {
      "&::before": {
        borderColor: `transparent transparent ${theme.palette.info.main} transparent`,
      },
    },
  },
  popperDuplicate: {
    '&[x-placement*="bottom"] $arrow': {
      "&::before": {
        borderColor: `transparent transparent ${theme.palette.error.main} transparent`,
      },
    },
  },
  arrow: {
    position: "absolute",
    fontSize: 7,
    width: "3em",
    height: "3em",
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid",
    },
  },
}));

const PlayerClueCard = ({ player, clue, isRevealed, isNullified }) => {
  const classes = useStyles();
  const anchorRef = React.useRef();
  const [arrowRef, setArrowRef] = React.useState(null);
  const [anchorReady, setAnchorReady] = React.useState(false);
  React.useEffect(() => {
    setAnchorReady(true);
  }, []);
  return (
    <div>
      <Card ref={anchorRef} className={classes.card}>
        <CardHeader
          avatar={<Avatar>{player.name.charAt(0)}</Avatar>}
          title={player.name}
          titleTypographyProps={{ variant: "h5" }}
          subheader={clue === null ? "Thinking" : "Submitted"}
        />

        {anchorReady && isRevealed && (
          <Popper
            disablePortal
            transition
            anchorEl={anchorRef.current}
            placement={"bottom"}
            modifiers={{
              flip: {
                enabled: false,
              },
              preventOverflow: {
                enabled: false,
                boundariesElement: "scrollParent",
              },
              arrow: {
                enabled: true,
                element: arrowRef,
              },
            }}
            className={clsx(
              classes.popper,
              isNullified && classes.popperDuplicate,
              !isNullified && classes.popperClue,
            )}
            open
          >
            <span className={classes.arrow} ref={setArrowRef} />
            <Alert
              severity={isNullified ? "error" : "info"}
              icon={isNullified ? undefined : false}
              className={clsx(!isNullified && classes.clue)}
              variant={"outlined"}
            >
              {isNullified ? "Duplicated!" : clue}
            </Alert>
          </Popper>
        )}
      </Card>
    </div>
  );
};

export default PlayerClueCard;
