import React from "react";
import {
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

const PlayerStart = ({ startGame }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleStartGame = () => {
    setOpen(false);
    startGame();
  };

  return (
    <>
      <Box m={3} p={2}>
        <Button variant={"outlined"} size={"large"} onClick={handleClickOpen}>
          Start
        </Button>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Start game?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please ensure all players have connected and are ready.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleStartGame} autoFocus>
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PlayerStart;
