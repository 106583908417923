import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import PlayerList from "./PlayerList";
import Logo from "../../components/Logo";
import { identify } from "../../utils/Logging";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
  },
  root: {
    //backgroundColor: "#282c34",
    minHeight: "94vh",
    display: "flex",
    fontSize: "calc(10px + 2vmin)",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  middleContent: {
    textAlign: "center",
    flex: "0",
    marginTop: "-0.5em",
  },
  button: {
    marginLeft: "3em",
  },
  panel: {
    flex: "1 1 36%",
  },
}));

const HostingLobbyScreen = ({ roomNumber, players }) => {
  const classes = useStyles();
  React.useEffect(() => {
    identify({ room: roomNumber, isHost: true });
  }, [roomNumber]);
  return (
    <div className={classes.container}>
      <div className={classes.panel}>
        <PlayerList playerList={players} />
      </div>
      <div className={classes.panel}>
        <div className={classes.root}>
          <Box flex={"1.3 1"} />
          <Logo />
          <Box flex={"2 1"}>
            <Box marginLeft={"-7.5em"}>
              <Typography variant="caption" fontSize={8}>
                Room
              </Typography>
            </Box>
            <Typography variant="h1" fontSize={19}>
              {roomNumber}
            </Typography>
            <Box margin={3} padding={2}>
              {players.length <= 0 && (
                <Typography variant="h5">Waiting for players...</Typography>
              )}
              {players.length > 0 && (
                <Typography variant="h5">
                  {players.length} player(s) connected
                </Typography>
              )}
            </Box>
          </Box>
        </div>
      </div>
      <div className={classes.panel} />
    </div>
  );
};

export default HostingLobbyScreen;
