import { connect } from "react-redux";
import HomeScreen from "./HomeScreen";
import { coreOperations } from "../../modules/core";
import { roomOperations } from "../../modules/room";

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({
  createRoom: () => dispatch(roomOperations.createRoom()),
  joinLobby: () => dispatch(coreOperations.joinLobby()),
});

const HomeScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(HomeScreen);
export default HomeScreenContainer;
