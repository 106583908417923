import { connect } from "react-redux";
import ClientScreen from "./ClientScreen";
import { gameOperations, gameSelectors } from "../../modules/game";

const mapStateToProps = state => ({
  step: gameSelectors.getPlayerStep(state),
  word: gameSelectors.getWord(state),
});
const mapDispatchToProps = {
  setTurnOrder: gameOperations.setTurnOrder,
  sendPlayerStatus: gameOperations.sendPlayerTurn,
  submitGuess: gameOperations.submitGuess,
  submitClue: gameOperations.submitClue,
};

const ClientScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClientScreen);
export default ClientScreenContainer;
