import actions from "./actions";
import types from "./types";

const {
  setWords,
  setGameState,
  submitClue,
  submitGuess,
  setTurnOrder,
  setTurn,
  setClues,
  submitClueSuccess,
  submitClueError,
  setGuess,
  syncClient,
} = actions;

const sendPlayerTurn = turn => {
  return {
    type: types.SEND_PLAYER_TURN,
    turn,
  };
};

const sendPlayerTurnSuccess = payload => {
  return {
    type: types.SEND_PLAYER_TURN_SUCCESS,
    payload,
  };
};

const sendPlayerTurnError = payload => {
  return {
    type: types.SEND_PLAYER_TURN_ERROR,
    payload,
  };
};

const initializeClients = gameState => {
  return {
    type: types.INITIALIZE_CLIENTS,
    gameState,
  };
};

const initializeClientsSuccess = payload => {
  return {
    type: types.INITIALIZE_CLIENTS_SUCCESS,
    payload,
  };
};

const initializeClientsError = payload => {
  return {
    type: types.INITIALIZE_CLIENTS_ERROR,
    payload,
  };
};

export default {
  setWords,
  setGameState,
  submitClue,
  submitGuess,
  setTurnOrder,
  sendPlayerTurn,
  sendPlayerTurnSuccess,
  sendPlayerTurnError,
  initializeClients,
  initializeClientsSuccess,
  initializeClientsError,
  setTurn,
  setClues,
  submitClueSuccess,
  submitClueError,
  setGuess,
  syncClient,
};
