import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { ClockLoader } from "react-spinners";

const useStyles = makeStyles(() => ({
  bottomRight: {
    position: "absolute",
    right: "1rem",
    bottom: "1rem",
    opacity: 0,
  },
  fadeInAnimation: {
    animation: "$fadeInAnimation-keyframes 2.5s ease-in-out 0.5s forwards",
  },
  "@keyframes fadeInAnimation-keyframes": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    },
  },
}));

const BottomCornerTimer = () => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.bottomRight, classes.fadeInAnimation)}>
      <ClockLoader color={"#ffffff"} size={60} />
    </div>
  );
};

export default BottomCornerTimer;
